import { NgModule, ModuleWithProviders, Optional, SkipSelf, ErrorHandler } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SystemModule } from '@libs/portal-common/system';

import { AudioRecordsService } from './services/audio-records.service';
import { VideoRecordsService } from './services/video-records.service';
import { CallCenterService } from './services/call-center.service';
import { ContactsService } from './services/contacts.service';
import { AgentFiltersService } from './services/agent-filters.service';

import { FieldSetService } from './services/field-set.service';
import { FilesService } from './services/files.service';
import { IncidentService } from './services/incident.service';
import { IssuesService } from './services/issues.service';
import { LanesService } from './services/lanes.service';

import { LocationsListService } from './services/locations-list.service';
import { LocationsService } from './services/locations.service';
import { LotIssuesService } from './services/lot-issues.service';
import { ProblemsService } from './services/problems.service';
import { ProvidersService } from './services/providers.service';

import { ReportsService } from './services/reports.service';
import { RegionsService } from './services/regions.service';
import { RevenueControlService } from './services/revenue-control.service';
import { SignalRRestClientService } from './services/signalr-rest-client.service';
import { StatisticsService } from './services/statistics.service';
import { UpdatesService } from './services/updates.service';

import { ActivitySignalRService } from './services/activity-signalr.service';
import { AgentsMonitoringSignalRService } from './services/agents-monitoring-signalr.service';

import { AgentService } from './services/agent.service';
import { LocationLaneResolverService } from './services/location-lane-resolver.service';

import { AuthorizationService } from './services/access-management/authorization.service';
import { AuthService } from './services/access-management/auth.service';

import { AuthenticatedGuard, OnlyAnonimousGuard } from './guards/auth.guards';

import { AppStateService } from './services/app-state.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TransferStateInterceptor } from './interceptors/transfer-state.interceptor';

import { CallCenterStatisticService } from './services/call-center-statistic.service';
import { IncidentsQualityAnalysisService } from './services/incident-quality-analysis.service';

import { AccessManagementService } from './services/access-management/access-management.service';
import { PermissionsGuard } from './services/access-management/permissions.guard';
import { RedirectGuard } from './services/access-management/redirect.guard';

import { IdentityProvidersService } from './services/identity-providers.service';
import { ClaimMappingService } from './services/claim-mapping.service';
import { RuleSetService } from './services/rule-set.service';
import { DefaultRuleSetService } from './services/default-rule-set.service';
import { IssueFieldsService } from './services/issue-fields.service';
import { TagsService } from './services/tags.service';
import { CallMatchingGroupsService } from './services/call-matching-groups.service';
import { AzureService } from './services/azure.service';
import { FieldGatewaysService } from './services/fieldGateways.service';
import { CallCenterParkonectCredentialsService } from './services/call-center-parkonect-credentials.service';
import { MonitoringSettingsService } from './services/monitoring-settings.service';
import { TicketsService } from './services/tickets.service';
import { VoicePlatformService } from './services/voice-platform.service';
import { OcraService } from './services/ocra.service';
import { GlobalErrorHandler } from './global.error.handler';
import { VoiceBotsService } from './services/voice-bots.service';
import { KendoScheduleNotificationsService } from './services/kendo-schedule-notifications.service';
import { CustomerConfigService } from './services/customer-config.service';
import { PowerAutomateService } from './services/power-automate.service';
import { LiveQueueMonitorService } from './services/live-queue-monitor.service';
import { NoodoeService } from './services/noodoe.service';
import { MitteService } from './services/mitte.service';
import { LoaderStatusService } from './services/loader-status.service';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ParkChirpService } from './services/parkchirp.service';
import { CallCenterJobsService } from './services/call-center-jobs.service';
import { InexLPRService } from './services/inex-lpr.service';

@NgModule({
  imports: [HttpClientModule, SystemModule],
  exports: [HttpClientModule],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoadingInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TransferStateInterceptor,
          multi: true,
        },

        AppStateService,
        AuthenticatedGuard,
        OnlyAnonimousGuard,
        UpdatesService,
        AudioRecordsService,
        VideoRecordsService,
        CallCenterService,
        AgentService,
        ContactsService,
        FieldSetService,
        FilesService,
        IncidentService,
        IssuesService,
        LanesService,
        LocationsService,
        LotIssuesService,
        ProblemsService,
        ProvidersService,
        ActivitySignalRService,
        AgentsMonitoringSignalRService,
        CallCenterStatisticService,
        AuthorizationService,
        AuthService,
        AgentFiltersService,
        ReportsService,
        RegionsService,
        RevenueControlService,
        SignalRRestClientService,
        StatisticsService,
        LocationsListService,
        LocationLaneResolverService,
        CallCenterStatisticService,
        IncidentsQualityAnalysisService,
        AccessManagementService,
        PermissionsGuard,
        RedirectGuard,
        RuleSetService,
        DefaultRuleSetService,
        IssueFieldsService,
        IdentityProvidersService,
        ClaimMappingService,
        TagsService,
        CallMatchingGroupsService,
        AzureService,
        FieldGatewaysService,
        CallCenterParkonectCredentialsService,
        MonitoringSettingsService,
        TicketsService,
        VoicePlatformService,
        VoiceBotsService,
        OcraService,
        NoodoeService,
        MitteService,
        ParkChirpService,
        KendoScheduleNotificationsService,
        CustomerConfigService,
        PowerAutomateService,
        LiveQueueMonitorService,
        LoaderStatusService,
        InexLPRService,
        CallCenterJobsService,
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {}
}
