import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { State } from '@progress/kendo-data-query';

import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { AppNotificationsService } from '@libs/portal-common/system';
import { AgentFilter } from './agent-filters.service';
import { CallCenterStatisticSettings } from '../models/callCenterStatisticSettings';
import { AudioRecordsSettings } from '../models/audioRecordsSettings';
import { VideoRecordSettings } from '../models/videoRecordsSettings';

export const TIMEZONES = [
  { Id: 'EST', Name: 'Eastern Standard Time' },
  { Id: 'CST', Name: 'Central Standard Time' },
  { Id: 'PST', Name: 'Pacific Standard Time' },
  { Id: 'MST', Name: 'Mountain Standard Time' },
];

export interface ICallCenter {
  Id?: number;
  CallCenterName: string;
  IsActive: boolean;
  APIKey: string;
  TimeZone: string;
  modified?: Date;

  fkRegion: number;
  AutoAnswerCalls: boolean;

  QueueOkSeconds?: number;
  QueueWarningSeconds?: number;
  HangUpOkSeconds?: number;
  HangUpWarningSeconds?: number;
  WrapUpOkSeconds?: number;
  WrapUpWarningSeconds?: number;

  IsAuthCodeSendingEnabled: boolean;
  MandatoryIssue: boolean;
}

export class CreateCallCenterModel {
  callCenter: ICallCenter;
  sourceRegionId: number;

  callCenterStatisticSettings: CallCenterStatisticSettings;
  audioRecordsSettings: AudioRecordsSettings;
  videoRecordsSettings: VideoRecordSettings;
  agentFilters: Array<AgentFilter>;

  constructor() {
    this.callCenter = {
      CallCenterName: null,
      IsActive: true,
      APIKey: null,
      TimeZone: null,
      fkRegion: null,
      AutoAnswerCalls: false,
      IsAuthCodeSendingEnabled: true,
      MandatoryIssue: false,
    };

    this.callCenterStatisticSettings = <CallCenterStatisticSettings>{ Id: 0 };
    this.audioRecordsSettings = null;
    this.videoRecordsSettings = null;
    this.agentFilters = [];
  }
}

export interface IGetCallCenterResponse extends BaseResponse {
  CallCenter: ICallCenter;
}

export interface IGetCallCentersListResponse extends BaseResponse {
  CallCenters: Array<ICallCenter>;
}

export interface GetCallCenterTypes extends BaseResponse {
  Types: Array<string>;
}

export interface GetCallCenterSettingsResponse extends BaseResponse {
  Type: string;
  DesktopAgentLogLevel: string;
}

export interface GetLogLevels extends BaseResponse {
  Levels: Array<string>;
}

export interface GetDesktopAgentLogLevelResponse extends BaseResponse {
  Type: string;
}

@Injectable({ providedIn: 'root' })
export class CallCenterService {
  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {}

  getAll(): Observable<IGetCallCentersListResponse> {
    return this._httpClient.get<IGetCallCentersListResponse>('CallCenter');
  }

  getCallCenter(id: number): Observable<IGetCallCenterResponse> {
    return this._httpClient.get<IGetCallCenterResponse>(`CallCenter/${id}`).pipe(
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
    );
  }

  update(model: ICallCenter): Observable<any> {
    return this._httpClient.put<any>('CallCenter', { CallCenter: model });
  }

  insert(model: CreateCallCenterModel): Observable<any> {
    return this._httpClient.post<any>('CallCenter', model);
  }

  validateCreateCallCenterStep(model: CreateCallCenterModel): Observable<any> {
    return this._httpClient.post<any>('CallCenter/ValidateCreateCallCenterStep', model);
  }

  validateCopyRegionDataStep(model: CreateCallCenterModel): Observable<any> {
    return this._httpClient.post<any>('CallCenter/ValidateCopyRegionDataStep', model);
  }

  validateAgentFiltersStep(model: CreateCallCenterModel): Observable<any> {
    return this._httpClient.post<any>('CallCenter/ValidateAgentFiltersStep', model);
  }

  validateAudioSettingsStep(model: CreateCallCenterModel): Observable<any> {
    return this._httpClient.post<any>('CallCenter/ValidateAudioSettingsStep', model);
  }

  validateCallCenterStatisticSettingsStep(model: CreateCallCenterModel): Observable<any> {
    return this._httpClient.post<any>('CallCenter/ValidateCallCenterStatisticSettingsStep', model);
  }

  getCallCenterTypes() {
    return this._httpClient.get<GetCallCenterTypes>('CallCenter/Settings/Type');
  }

  changeCallCenterType(type: string) {
    return this._httpClient.put<BaseResponse>('CallCenter/Settings/Type', { Type: type });
  }

  getCurrentSettings() {
    return this._httpClient.get<GetCallCenterSettingsResponse>('/CallCenter/Settings');
  }

  getLogLevels() {
    return this._httpClient.get<GetLogLevels>('CallCenter/Settings/LogLevels');
  }

  changeAgentLogLevel(level: string) {
    return this._httpClient.put<BaseResponse>('CallCenter/Settings/LogLevels', { DesktopAgentLogLevel: level });
  }

  delete(id: number): Observable<any> {
    let params = new HttpParams().set('id', id.toString());

    return this._httpClient.delete<any>('CallCenter', { params: params });
  }
}
